/*  DisplayPreview Class
 *  Justin Bevan
 *
 *--------------------------------------------------------------------------*/

var DisplayPreview =
{
    data: {
        lastSyncTime:       false,
        loadedVideoSources: [],
        usedSlideEffects:   [],
        menuItemsTouched:   [],
        imageSources:       [],
        lastSlideStart:     null,
        androidQueue:       []
    },

    /**
     * Constructor
     *
     */
    init: function(baseLink, key, themeOptions)
    {
        this.baseLink           = baseLink;
        this.key                = key;
        this.themeOptions       = themeOptions;
        this.loadedVideoSources = [];

        this.initBindings();
        //this.preloadVideos();
    },

    /**
     * Init Bindings
     *
     */
    initBindings: function()
    {
        window.addEventListener("resize", this.resizeDesignerBlocks);
        this.resizeDesignerBlocks();
    },

    logger: function(message, type)
    {
        if(this.isRunningIE())
        {
            return false;
        }

        switch(type)
        {
            case 'log':

                console.log(message);
                break;

            case 'error':

                console.error(message);
                break;
        }
    },

    /**
     * Update Last Sync Time
     *
     */
    updateLastSync: function(refreshPage)
    {
        if(this.isRunningIE())
        {
            return false;
        }

        var response = this.runRequest(this.baseLink + '/display/checkupdate/' + this.key);

        if(typeof response.message != 'undefined')
        {
            if(!moment(response.message.date).isSame(moment(this.data.lastSyncTime.date)))
            {
                if(typeof refreshPage == 'undefined' && this.data.lastSyncTime != false)
                {
                    console.debug('Update Found!');
                    this.triggerEventOnContentUpdate();
                }
            }

            this.data.lastSyncTime = response.message;
        }
    },

    /**
     * Get Last Sync Time
     *
     */
    getLastSyncTime: function()
    {
        if(typeof this.data.lastSyncTime.date != 'undefined')
        {
            return this.data.lastSyncTime.date;
        }

        return false;
    },

    /**
     * Is Running IE
     *
     */
    isRunningIE: function()
    {
        var ua      = window.navigator.userAgent,
            msie    = ua.indexOf("MSIE ");

        return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    },

    /**
     * Run Request
     *
     */
    runRequest: function(url, params)
    {
        // Set response variable
        var response;

        // Run Ajax Call
        jQuery.ajax({
            'async': false,
            'type': "GET",
            'global': false,
            'dataType': 'json',
            'data': params,
            'url': url,
            'complete': function (data) {
                response = data;
            }
        });

        if(typeof response.responseText == 'undefined' || response.responseText == '')
        {
            return false;
        }

        if(View.isJSON(response.responseText))
        {
            response = jQuery.parseJSON(response.responseText);

            if(typeof response == 'object')
            {
                return response
            }

            return false;
        }

        return false
    },

    /**
     * Update Display Data
     *
     */
    triggerEventOnContentUpdate: function()
    {
        setTimeout(window.location.reload.bind(window.location), 240000);
    },

    /**
     * Event - Before Slide Transition
     *
     * @param element
     * @param oldIndex
     * @param newIndex
     */
    eventOnBeforeTransition: function(element, oldIndex, newIndex)
    {
        var currentSlideIndex   = oldIndex,
            currentElement      = jQuery('.slide-img').eq(currentSlideIndex);

        this.runThemeAnimations();
    },

    /**
     * Run Slider Load Check
     *
     * @param currentIndex
     */
    runSliderLoadCheck: function(currentIndex)
    {
        var currentElement      = jQuery('.slide-img').eq(currentIndex),
            currentVideo        = currentElement.find('video');

        if(typeof currentVideo[0] !== "undefined")
        {
            var video = currentVideo[0];

            this.playVideoWhenReady(video);
        }

        this.data.lastSlideStart = {
            'time': this.getCurrentDateTime()
        };
    },

    /**
     * Run Pre Slide Check
     *
     * @param element
     * @param oldIndex
     * @param newIndex
     */
    runPreSlideCheck: function(element, oldIndex, newIndex)
    {
        var currentSlideIndex   = this.slider.getCurrentSlide(),
            currentElement      = jQuery('.slide-img').eq(currentSlideIndex),
            currentVideo        = currentElement.find('video'),
            imageSource         = currentElement.find('img').attr('src');

        if(typeof imageSource !== "undefined")
        {
            var extension = imageSource.split('.').pop();

            if(extension && extension.toLowerCase() == 'gif')
            {
                currentElement.find('img').show();
                setTimeout(function() {
                    currentElement.find('img').attr('src', imageSource);
                }, 0);
            }

        }

        if(typeof currentVideo[0] !== "undefined")
        {
            var video = currentVideo[0];

            this.playVideoWhenReady(video);
        }
    },

    /**
     * Run Post Slide Check
     *
     * @param element
     * @param oldIndex
     * @param newIndex
     */
    runPostSlideCheck: function(element, oldIndex, newIndex)
    {
        var currentSlideIndex   = oldIndex,
            currentElement      = jQuery('.slide-img').eq(currentSlideIndex),
            currentVideo        = currentElement.find('video'),
            imageSource         = currentElement.find('img').attr('src');

        if(typeof imageSource !== "undefined")
        {
            var extension   = imageSource.split('.').pop();

            if(extension && extension.toLowerCase() == 'gif')
            {
                currentElement.find('img').hide();
            }

        }

        if(typeof currentVideo[0] !== "undefined")
        {
            var video = currentVideo[0];

            if(!video.paused)
            {
                video.pause();
                video.currentTime = 0;
            }
        }
    },

    /**
     * Preload Videos
     *
     */
    preloadVideos: function()
    {
        var context = this,
            videos  = document.getElementsByTagName('video');

        if(videos.length)
        {
            for(var i = 0; i < videos.length; i++)
            {
                var video = videos[i];

                video.load();

                /*video.addEventListener('onloadeddata', function()
                 {
                 console.log("Preloaded Video", this.src);
                 context.data.loadedVideoSources.push(this.src);
                 }, false);*/
            }
        }
    },

    /**
     * Play Video When Ready
     *
     * @param videoElement
     */
    playVideoWhenReady: function(videoElement)
    {
        var context = this;

        jQuery(videoElement).unbind('loadedmetadata loadeddata canplaythrough');

        videoElement.load();

        setTimeout(function()
        {
            videoElement.play();
        }, 0);

        this.logger("Video Loading...", 'log');

        /*
        videoElement.addEventListener('loadeddata', function() {
            videoElement.play(); //this first play is needed for Android 4.1+
        }, false);
        */

        /*
        if(videoElement.paused)
        {
            //videoElement.load();

            console.log("Playing Video", videoElement);

            jQuery(videoElement).unbind('loadeddata error');

            videoElement.addEventListener('loadeddata', function()
            {
                console.log("Data Loaded");
            }, false);

            videoElement.addEventListener('canplay', function()
            {
                console.log("Video Can Play");
                videoElement.play();
            }, false);

            videoElement.addEventListener('error', function(event)
            {
                if(event.target.error)
                {
                    alert("Video Error - " + event.target.error)
                }
            }, false);
        }*/
    },

    /**
     * Event - After Slide Transition
     *
     */
    eventOnAfterSlideTransition: function(element, oldIndex, newIndex)
    {
        var currentSlideIndex   = oldIndex,
            currentElement      = jQuery('.slide-img').eq(currentSlideIndex),
            slideData           = currentElement.data();

        if(slideData.slideId)
        {
            var lastSlideDetails    = jQuery.extend({}, this.data.lastSlideStart),
                startTime           = lastSlideDetails.time,
                endTime             = this.getCurrentDateTime(),
                timeDiff            = Math.abs(moment(endTime).diff(moment(startTime), 'seconds'));

            var params = {
                type:   'Advertisement',
                itemId: slideData.slideId,
                start:  startTime,
                end:    endTime
            };

            this.addToAndroidQueue(params);

            this.data.lastSlideStart = {
                'time': this.getCurrentDateTime()
            };
        }
    },

    /**
     * Event on Next Slide
     *
     */
    eventOnNextSlide: function()
    {

    },

    /**
     * Event - After Slideshow Completed
     *
     */
    eventOnSlideShowEnd: function()
    {

    },

    /**
     * Event - On Last Slide Shown
     *
     */
    eventOnLastSlideShown: function()
    {

    },

    /**
     * Event - On Slideshow Loaded
     *
     */
    eventOnSlideShowLoaded: function()
    {

    },

    /**
     * Run Theme Animations
     *
     */
    runThemeAnimations: function()
    {
        if(typeof this.themeOptions == 'undefined' || this.themeOptions == null || typeof this.themeOptions.sliderSettings.effects == 'undefined')
        {
            return false;
        }

        this.themeOptions.sliderSettings.params = {
            change: 'random',
            speed: '2000'
        };

        var effectToUse         = this.getSlideEffectToUse();
        var currentSlideIndex   = this.slider.getCurrentSlide();

        jQuery('.slide-inf').html(
            'Current Slide: <b>' + currentSlideIndex + '</b><br />' +
            'Current Effect: <b>' + effectToUse + '</b><br />'
        );

        jQuery('.slide-img').find('img').attr('class', 'tile-img animated');

        this.addAnimationToSlide(currentSlideIndex, effectToUse);
    },

    /**
     * Run Menu Theme Updates
     *
     */
    runMenuThemeUpdates: function()
    {
        if(typeof this.themeOptions.menuboardSettings.effects == 'undefined' || typeof this.themeOptions.menuboardSettings.effects == '')
        {
            return false;
        }

        var menus               = jQuery('.viewBox'),
            assignedItems       = this.themeOptions.menuboardSettings.assign,
            effect              = this.themeOptions.menuboardSettings.effects,
            currentProperty     = null;

        menus.removeClass('animated topIndex ' + effect);

        for(var i = 0; i < menus.length; i++)
        {
            var element = menus.eq(i),
                time    = 'time' + this.themeOptions.menuboardSettings.effect_speed;

            if(typeof this.themeOptions.menuboardSettings.assign != 'undefined' && this.themeOptions.menuboardSettings.apply == 'selected')
            {
                assignedItems = this.themeOptions.menuboardSettings.assign;

                if(assignedItems.indexOf(i.toString()) > -1)
                {
                    if(this.data.menuItemsTouched.indexOf(i) <= -1)
                    {
                        currentProperty = this.getMenuTransitionPoint(element);

                        jQuery('.debug-inf').html(currentProperty);

                        element.addClass('animated '+ effect +' '+ time +' '+ currentProperty +' topIndex');

                        this.data.menuItemsTouched.push(i);

                        if(this.data.menuItemsTouched.length === menus.length)
                        {
                            this.data.menuItemsTouched = [];
                        }

                        if(this.data.menuItemsTouched.length == assignedItems.length)
                        {
                            this.data.menuItemsTouched = [];
                        }

                        break;
                    }
                }
            }
            else
            {
                if(this.data.menuItemsTouched.indexOf(i) <= -1)
                {
                    currentProperty = this.getMenuTransitionPoint(element);

                    jQuery('.debug-inf').html(currentProperty);

                    element.addClass('animated '+ effect +' '+ time +' '+ currentProperty +' topIndex');

                    this.data.menuItemsTouched.push(i);

                    if(this.data.menuItemsTouched.length === menus.length)
                    {
                        this.data.menuItemsTouched = [];
                    }

                    break;
                }
            }
        }

        if(typeof assignedItems != 'undefined' && assignedItems.length == 1 && this.themeOptions.menuboardSettings.apply == 'selected')
        {
            var timeToRun = parseInt(this.themeOptions.menuboardSettings.effect_speed);

            window.setTimeout( function() {
                menus.eq(assignedItems[0]).attr('class', 'menu-flex-item');
            }, timeToRun + 10)
        }
    },

    /**
     * Calculate Menu Delay
     *
     * @returns {number}
     */
    calculateMenuDelay: function()
    {
        if(typeof this.themeOptions.menuboardSettings != 'undefined' && typeof this.themeOptions.menuboardSettings.effect_speed != 'undefined')
        {
            if(typeof this.themeOptions.menuboardSettings.effect_delay != 'undefined')
            {
                if(this.themeOptions.menuboardSettings.effect_delay % 1 === 0)
                {
                    return parseInt(this.themeOptions.menuboardSettings.effect_speed) + parseInt(this.themeOptions.menuboardSettings.effect_delay);
                }
            }

            return parseInt(this.themeOptions.menuboardSettings.effect_speed) + 1000;
        }
    },

    /**
     * Get Effect to Use
     *
     * @returns {*}
     */
    getSlideEffectToUse: function()
    {
        if(typeof this.themeOptions.sliderSettings.effect_action == 'undefined')
        {
            return this.cycleEffectsRandomly();
        }

        var sliderThemeAction = this.themeOptions.sliderSettings.effect_action;

        switch(sliderThemeAction)
        {
            case 'random':

                return this.cycleEffectsRandomly();
                break;

            case 'asc':

                return this.cycleEffectsAscending();
                break;

            case 'desc':

                return this.cycleEffectsDescending();
                break;

            default:

                return this.cycleEffectsDescending();
                break;
        }
    },

    /**
     * Cycle Effects Randomly
     *
     * @returns {*}
     */
    cycleEffectsRandomly: function()
    {
        var sliderEffects   = this.themeOptions.sliderSettings.effects;
        var effectToUse     = sliderEffects[Math.floor(Math.random()*sliderEffects.length)];

        if(this.data.usedSlideEffects.length < sliderEffects.length)
        {
            if(this.data.usedSlideEffects.indexOf(effectToUse) > -1)
            {
                return this.getSlideEffectToUse();
            }

            this.data.usedSlideEffects.push(effectToUse);

            return effectToUse;
        }
        else
        {
            this.data.usedSlideEffects = [effectToUse];

            return effectToUse;
        }
    },

    /**
     * Cycle Effects - Ascending
     *
     * @returns {*}
     */
    cycleEffectsAscending: function()
    {
        var sliderEffects = this.themeOptions.sliderSettings.effects;

        return this.cycleEffects(sliderEffects);
    },

    /**
     * Cycle Effects - Descending
     *
     * @returns {*}
     */
    cycleEffectsDescending: function()
    {
        var sliderEffects = this.themeOptions.sliderSettings.effects;

        sliderEffects.reverse();

        return this.cycleEffects(sliderEffects);
    },

    /**
     * Cycle Effects in Order
     *
     * @param sliderEffects
     * @returns {*}
     */
    cycleEffects: function(sliderEffects)
    {
        if(this.data.usedSlideEffects.length < 1)
        {
            this.data.usedSlideEffects.push(sliderEffects[0]);

            return sliderEffects[0];
        }

        for(var i = 0; i < sliderEffects.length; i++)
        {
            if(this.data.usedSlideEffects.length < sliderEffects.length)
            {
                if(this.data.usedSlideEffects.indexOf(sliderEffects[i]) == -1)
                {
                    this.data.usedSlideEffects.push(sliderEffects[i]);

                    return sliderEffects[i];
                }
            }
            else
            {
                this.data.usedSlideEffects = [sliderEffects[i]];

                return sliderEffects[i];
            }

        }

        return sliderEffects[0];
    },

    /**
     * Get Next Slide Index
     *
     * @param currentSlideIndex
     * @returns {*}
     */
    getPrevSlideIndex: function(currentSlideIndex)
    {
        if(currentSlideIndex - 1 >= 0)
        {
            return currentSlideIndex - 1;
        }

        return 0;
    },

    /**
     * Get Next Slide Index
     *
     * @param currentSlideIndex
     * @returns {*}
     */
    getNextSlideIndex: function(currentSlideIndex)
    {
        var totalSlides = jQuery('.slide-img').length;

        if(currentSlideIndex + 1 <= totalSlides)
        {
            return currentSlideIndex + 1;
        }

        return 0;
    },

    /**
     * Add Animation to Next Index
     *
     * @param index
     * @param effect
     */
    addAnimationToSlide: function(index, effect)
    {
        var element = jQuery('.slide-img').eq(index);

        if(element.exists())
        {
            var effectClass = 'tile-img animated ' + effect;

            if(typeof this.themeOptions.sliderSettings.effect_speed != 'undefined' && this.themeOptions.sliderSettings.effect_speed != '')
            {
                effectClass += ' time' + this.themeOptions.sliderSettings.effect_speed;
            }

            element.find('img').addClass(effectClass);
        }
    },

    /**
     * Get Menu Transition Point
     *
     * @param element
     * @returns {*}
     */
    getMenuTransitionPoint: function(element)
    {
        var adjacents   = this.detectElementOuter(element),
            transition  = '';

        /**
         * Corner Transition Positions
         */
        // If nothing exists in the top left corner
        if(!adjacents.topLeft && !adjacents.leftCenter && !adjacents.topCenter && adjacents.bottomCenter)
        {
            transition = 'transform-top-left';
        }
        // If nothing exists in the top right corner
        else if(!adjacents.topRight && !adjacents.rightCenter && !adjacents.topCenter && adjacents.bottomCenter)
        {
            transition = 'transform-top-right';
        }
        // If nothing exists in bottom right corner
        else if(!adjacents.bottomLeft && !adjacents.leftCenter && !adjacents.bottomCenter)
        {
            transition = 'transform-bottom-left';
        }
        // If nothing exists in bottom right corner
        else if(!adjacents.bottomRight && !adjacents.rightCenter && !adjacents.bottomCenter && !adjacents.bottomLeft)
        {
            transition = 'transform-bottom-right';
        }
        else if(!adjacents.bottomRight && !adjacents.rightCenter && !adjacents.bottomCenter && adjacents.bottomLeft)
        {
            transition = 'transform-center-right';
        }

        /**
         * Other Transition Positions
         */
        // If transition is at the bottom in center
        else if(adjacents.leftCenter && adjacents.rightCenter && !adjacents.bottomCenter)
        {
            transition = 'transform-bottom-center';
        }
        // If transition is at the top in center
        else if(adjacents.leftCenter && adjacents.rightCenter && !adjacents.topCenter)
        {
            transition = 'transform-top-center';
        }
        // If transition is at the left in center
        else if(adjacents.topCenter && adjacents.bottomCenter && !adjacents.leftCenter)
        {
            transition = 'transform-center-left';
        }
        // If transition is at the right in center
        else if(adjacents.topCenter && adjacents.bottomCenter && !adjacents.rightCenter)
        {
            transition = 'transform-center-right';
        }
        else if(adjacents.topCenter && adjacents.bottomCenter && adjacents.leftCenter && adjacents.rightCenter)
        {
            transition = '';
        }

        if(transition)
        {
            return transition;
        }

        return false;
    },

    /**
     * Detect Element Outer Positions
     *
     * @param element
     * @returns {{topLeft: boolean, topCenter: boolean, topRight: boolean, bottomLeft: boolean, bottomCenter: boolean, bottomRight: boolean, leftCenter: boolean, rightCenter: boolean}}
     */
    detectElementOuter: function(element)
    {
        var offset          = element.offset();
        var menuBoxes       = jQuery('.menu-flex-item > div');

        var adjacents = {
            topLeft:        false,
            topCenter:      false,
            topRight:       false,
            bottomLeft:     false,
            bottomCenter:   false,
            bottomRight:    false,
            leftCenter:     false,
            rightCenter:    false
        };

        var elementProps = {
            top:    Math.round(offset.top),
            left:   Math.round(offset.left)
        };

        elementProps.bottom   = Math.round(elementProps.top + element.height());
        elementProps.right    = Math.round(elementProps.left + element.width());

        for(var j = 0; j < menuBoxes.length; j++)
        {
            var currentMenuBox  = jQuery(menuBoxes[j]);
            var currentOffset   = currentMenuBox.offset();

            if(offset.top == currentOffset.top && offset.left == currentOffset.left)
            {
                continue;
            }

            var boundaries = {
                top:    Math.round(currentOffset.top),
                left:   Math.round(currentOffset.left)
            };

            boundaries.bottom   = Math.round(boundaries.top + currentMenuBox.outerHeight());
            boundaries.right    = Math.round(boundaries.left + currentMenuBox.outerWidth());

            // If the elements top is higher or equal to the bottom of the element,
            // we'll check the left and right to determine it's position above
            if(elementProps.top >= boundaries.bottom)
            {
                if(elementProps.left == boundaries.left)
                {
                    adjacents.topCenter = true;
                }
                else if(elementProps.left > boundaries.left)
                {
                    adjacents.topLeft = true;
                }
                else if(elementProps.right < boundaries.right)
                {
                    adjacents.topRight = true;
                }
            }
            // If other element is below or at same level as bottom of current
            // we'll check the left and right boundaries to determine it's position below
            else if(elementProps.bottom <= boundaries.top)
            {
                if(elementProps.left == boundaries.left)
                {
                    adjacents.bottomCenter = true;
                }
                else if(elementProps.left > boundaries.left)
                {
                    adjacents.bottomLeft = true;
                }
                else if(elementProps.right < boundaries.right)
                {
                    adjacents.bottomRight = true;
                }
            }
            else if(elementProps.left > boundaries.left)
            {
                if(elementProps.top == boundaries.top)
                {
                    adjacents.leftCenter = true;
                }

                if(elementProps.top >= boundaries.top && elementProps.bottom >= boundaries.bottom)
                {
                    adjacents.leftCenter = true;
                }
            }
            else if(elementProps.right < boundaries.left)
            {
                if(elementProps.top == boundaries.top)
                {
                    adjacents.rightCenter = true;
                }
            }
        }

        return adjacents;
    },

    /**
     * Resize Designer Blocks
     *
     */
    resizeDesignerBlocks: function()
    {
        var designerBlocks  = document.querySelectorAll('.design-block'),
            designerCount   = designerBlocks.length;

        if(designerCount)
        {
            for(var i = 0; i < designerCount; i++)
            {
                var designBlock = designerBlocks[i];

                if(designBlock)
                {
                    var innerDesign	= designBlock.querySelector('.layer-output');

                    if(innerDesign)
                    {
                        var blockStyle 	= window.getComputedStyle(designBlock, null),
                            innerStyle	= window.getComputedStyle(innerDesign, null);

                        if(innerDesign)
                        {
                            var blockDimensions = {
                                    width: 	parseFloat(blockStyle.getPropertyValue("width")) - (parseFloat(blockStyle.getPropertyValue("padding-left")) * 2),
                                    height: parseFloat(blockStyle.getPropertyValue("height")) - (parseFloat(blockStyle.getPropertyValue("padding-top")) * 2)
                                },
                                innerDimensions = {
                                    width: 	parseFloat(innerStyle.getPropertyValue("width")),
                                    height: parseFloat(innerStyle.getPropertyValue("height"))
                                };

                            var scaleX = blockDimensions.width / innerDimensions.width,
                                scaleY = blockDimensions.height / innerDimensions.height;

                            var transform = 'scale(' + scaleX + ', ' + scaleY + ')';

                            innerDesign.style.transformOrigin   = 'left top';
                            innerDesign.style.transform 	    = transform;
                        }
                    }
                }
            }
        }
    },

    /**
     * Get Current Date Time
     *
     */
    getCurrentDateTime: function()
    {
        var date = new moment();
        return this.getFormattedDate(date);
    },

    /**
     * Get Formatted Date
     *
     * @param date
     * @returns {string}
     */
    getFormattedDate: function(date)
    {
        return date.format('YYYY-MM-DD h:mm:ss');
    },

    /**
     * Add to Android Queue
     *
     * @param dataToSend
     */
    addToAndroidQueue: function(dataToSend)
    {
        this.data.androidQueue.push(dataToSend);
    },

    /**
     * Clear Android Queue
     *
     */
    clearAndroidQueue: function()
    {
        this.data.androidQueue = [];
        this.data.androidQueue.length = 0;
    },

    /**
     * Process Android Queue
     *
     * @returns {*|boolean}
     */
    processAndroidQueue: function()
    {
        var queue = this.data.androidQueue;

        if(queue && queue.length)
        {
            return this.sendInformationToAndroid(queue);
        }
    },

    /**
     * Send to Android
     *
     * @param dataToSend
     * @returns {boolean}
     */
    sendInformationToAndroid: function(dataToSend)
    {
        var data = JSON.stringify(dataToSend);

        this.clearAndroidQueue();

        if(typeof ftxandroid != 'undefined' && typeof ftxandroid.sendPlayCount != 'undefined')
        {
            try
            {
                ftxandroid.sendPlayCount(data);
            }
            catch(e)
            {
                console.log(e);
            }
        }
        else
        {
            return false;
        }
    }
};